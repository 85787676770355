export const AdminProductCard = () => import('../../components/AdminProductCard.vue' /* webpackChunkName: "components/admin-product-card" */).then(c => wrapFunctional(c.default || c))
export const AdvancedFilter = () => import('../../components/AdvancedFilter.vue' /* webpackChunkName: "components/advanced-filter" */).then(c => wrapFunctional(c.default || c))
export const AiContent = () => import('../../components/AiContent.vue' /* webpackChunkName: "components/ai-content" */).then(c => wrapFunctional(c.default || c))
export const AnimatedSuccess = () => import('../../components/AnimatedSuccess.vue' /* webpackChunkName: "components/animated-success" */).then(c => wrapFunctional(c.default || c))
export const Attributes = () => import('../../components/Attributes.vue' /* webpackChunkName: "components/attributes" */).then(c => wrapFunctional(c.default || c))
export const Avatar = () => import('../../components/Avatar.vue' /* webpackChunkName: "components/avatar" */).then(c => wrapFunctional(c.default || c))
export const Avatars = () => import('../../components/Avatars.vue' /* webpackChunkName: "components/avatars" */).then(c => wrapFunctional(c.default || c))
export const BaseAuthView = () => import('../../components/BaseAuthView.vue' /* webpackChunkName: "components/base-auth-view" */).then(c => wrapFunctional(c.default || c))
export const BaseView = () => import('../../components/BaseView.vue' /* webpackChunkName: "components/base-view" */).then(c => wrapFunctional(c.default || c))
export const BranchCard = () => import('../../components/BranchCard.vue' /* webpackChunkName: "components/branch-card" */).then(c => wrapFunctional(c.default || c))
export const Cache = () => import('../../components/Cache.vue' /* webpackChunkName: "components/cache" */).then(c => wrapFunctional(c.default || c))
export const CartDrawer = () => import('../../components/CartDrawer.vue' /* webpackChunkName: "components/cart-drawer" */).then(c => wrapFunctional(c.default || c))
export const Categories = () => import('../../components/Categories.vue' /* webpackChunkName: "components/categories" */).then(c => wrapFunctional(c.default || c))
export const CategoriesModal = () => import('../../components/CategoriesModal.vue' /* webpackChunkName: "components/categories-modal" */).then(c => wrapFunctional(c.default || c))
export const CategoryCard = () => import('../../components/CategoryCard.vue' /* webpackChunkName: "components/category-card" */).then(c => wrapFunctional(c.default || c))
export const CategoryCards = () => import('../../components/CategoryCards.vue' /* webpackChunkName: "components/category-cards" */).then(c => wrapFunctional(c.default || c))
export const CheckMark = () => import('../../components/CheckMark.vue' /* webpackChunkName: "components/check-mark" */).then(c => wrapFunctional(c.default || c))
export const Color = () => import('../../components/Color.vue' /* webpackChunkName: "components/color" */).then(c => wrapFunctional(c.default || c))
export const ColorBtn = () => import('../../components/ColorBtn.vue' /* webpackChunkName: "components/color-btn" */).then(c => wrapFunctional(c.default || c))
export const Content = () => import('../../components/Content.vue' /* webpackChunkName: "components/content" */).then(c => wrapFunctional(c.default || c))
export const CountDown = () => import('../../components/CountDown.vue' /* webpackChunkName: "components/count-down" */).then(c => wrapFunctional(c.default || c))
export const CrudDrawer = () => import('../../components/CrudDrawer.vue' /* webpackChunkName: "components/crud-drawer" */).then(c => wrapFunctional(c.default || c))
export const DataTable = () => import('../../components/DataTable.vue' /* webpackChunkName: "components/data-table" */).then(c => wrapFunctional(c.default || c))
export const DebugToolbar = () => import('../../components/DebugToolbar.vue' /* webpackChunkName: "components/debug-toolbar" */).then(c => wrapFunctional(c.default || c))
export const Fcm = () => import('../../components/Fcm.vue' /* webpackChunkName: "components/fcm" */).then(c => wrapFunctional(c.default || c))
export const LoggingIn = () => import('../../components/LoggingIn.vue' /* webpackChunkName: "components/logging-in" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const MainSearch = () => import('../../components/MainSearch.vue' /* webpackChunkName: "components/main-search" */).then(c => wrapFunctional(c.default || c))
export const Media = () => import('../../components/Media.vue' /* webpackChunkName: "components/media" */).then(c => wrapFunctional(c.default || c))
export const MediumContainer = () => import('../../components/MediumContainer.vue' /* webpackChunkName: "components/medium-container" */).then(c => wrapFunctional(c.default || c))
export const MobileMainMenu = () => import('../../components/MobileMainMenu.vue' /* webpackChunkName: "components/mobile-main-menu" */).then(c => wrapFunctional(c.default || c))
export const MobileSearchMenu = () => import('../../components/MobileSearchMenu.vue' /* webpackChunkName: "components/mobile-search-menu" */).then(c => wrapFunctional(c.default || c))
export const MoneyField = () => import('../../components/MoneyField.vue' /* webpackChunkName: "components/money-field" */).then(c => wrapFunctional(c.default || c))
export const MyAccountManager = () => import('../../components/MyAccountManager.vue' /* webpackChunkName: "components/my-account-manager" */).then(c => wrapFunctional(c.default || c))
export const MyMenu = () => import('../../components/MyMenu.vue' /* webpackChunkName: "components/my-menu" */).then(c => wrapFunctional(c.default || c))
export const NoWishlistItems = () => import('../../components/NoWishlistItems.vue' /* webpackChunkName: "components/no-wishlist-items" */).then(c => wrapFunctional(c.default || c))
export const Overlay = () => import('../../components/Overlay.vue' /* webpackChunkName: "components/overlay" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethod = () => import('../../components/PaymentMethod.vue' /* webpackChunkName: "components/payment-method" */).then(c => wrapFunctional(c.default || c))
export const PriceProduct = () => import('../../components/PriceProduct.vue' /* webpackChunkName: "components/price-product" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductListItem = () => import('../../components/ProductListItem.vue' /* webpackChunkName: "components/product-list-item" */).then(c => wrapFunctional(c.default || c))
export const ProductSelectModal = () => import('../../components/ProductSelectModal.vue' /* webpackChunkName: "components/product-select-modal" */).then(c => wrapFunctional(c.default || c))
export const ProductsAttributes = () => import('../../components/ProductsAttributes.vue' /* webpackChunkName: "components/products-attributes" */).then(c => wrapFunctional(c.default || c))
export const ProductsComparison = () => import('../../components/ProductsComparison.vue' /* webpackChunkName: "components/products-comparison" */).then(c => wrapFunctional(c.default || c))
export const ProductsSlider = () => import('../../components/ProductsSlider.vue' /* webpackChunkName: "components/products-slider" */).then(c => wrapFunctional(c.default || c))
export const PublishField = () => import('../../components/PublishField.vue' /* webpackChunkName: "components/publish-field" */).then(c => wrapFunctional(c.default || c))
export const PublishStatus = () => import('../../components/PublishStatus.vue' /* webpackChunkName: "components/publish-status" */).then(c => wrapFunctional(c.default || c))
export const Responsive = () => import('../../components/Responsive.vue' /* webpackChunkName: "components/responsive" */).then(c => wrapFunctional(c.default || c))
export const SecondaryBar = () => import('../../components/SecondaryBar.vue' /* webpackChunkName: "components/secondary-bar" */).then(c => wrapFunctional(c.default || c))
export const ShareButtons = () => import('../../components/ShareButtons.vue' /* webpackChunkName: "components/share-buttons" */).then(c => wrapFunctional(c.default || c))
export const ShippingCarrierInformation = () => import('../../components/ShippingCarrierInformation.vue' /* webpackChunkName: "components/shipping-carrier-information" */).then(c => wrapFunctional(c.default || c))
export const SlideShow = () => import('../../components/SlideShow.vue' /* webpackChunkName: "components/slide-show" */).then(c => wrapFunctional(c.default || c))
export const Sparkline = () => import('../../components/Sparkline.vue' /* webpackChunkName: "components/sparkline" */).then(c => wrapFunctional(c.default || c))
export const StickySidebar = () => import('../../components/StickySidebar.vue' /* webpackChunkName: "components/sticky-sidebar" */).then(c => wrapFunctional(c.default || c))
export const Stocks = () => import('../../components/Stocks.vue' /* webpackChunkName: "components/stocks" */).then(c => wrapFunctional(c.default || c))
export const Thumbnail = () => import('../../components/Thumbnail.vue' /* webpackChunkName: "components/thumbnail" */).then(c => wrapFunctional(c.default || c))
export const Toolbar = () => import('../../components/Toolbar.vue' /* webpackChunkName: "components/toolbar" */).then(c => wrapFunctional(c.default || c))
export const Tooltip = () => import('../../components/Tooltip.vue' /* webpackChunkName: "components/tooltip" */).then(c => wrapFunctional(c.default || c))
export const Translatable = () => import('../../components/Translatable.vue' /* webpackChunkName: "components/translatable" */).then(c => wrapFunctional(c.default || c))
export const UnderConstruction = () => import('../../components/UnderConstruction.vue' /* webpackChunkName: "components/under-construction" */).then(c => wrapFunctional(c.default || c))
export const Upload = () => import('../../components/Upload.vue' /* webpackChunkName: "components/upload" */).then(c => wrapFunctional(c.default || c))
export const UsedCategories = () => import('../../components/UsedCategories.vue' /* webpackChunkName: "components/used-categories" */).then(c => wrapFunctional(c.default || c))
export const UsedCategoryCard = () => import('../../components/UsedCategoryCard.vue' /* webpackChunkName: "components/used-category-card" */).then(c => wrapFunctional(c.default || c))
export const UsedProductAttributes = () => import('../../components/UsedProductAttributes.vue' /* webpackChunkName: "components/used-product-attributes" */).then(c => wrapFunctional(c.default || c))
export const UsedProductCard = () => import('../../components/UsedProductCard.vue' /* webpackChunkName: "components/used-product-card" */).then(c => wrapFunctional(c.default || c))
export const VarianceCard = () => import('../../components/VarianceCard.vue' /* webpackChunkName: "components/variance-card" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayer = () => import('../../components/VideoPlayer.vue' /* webpackChunkName: "components/video-player" */).then(c => wrapFunctional(c.default || c))
export const WishlistDrawer = () => import('../../components/WishlistDrawer.vue' /* webpackChunkName: "components/wishlist-drawer" */).then(c => wrapFunctional(c.default || c))
export const YoutubeVideo = () => import('../../components/YoutubeVideo.vue' /* webpackChunkName: "components/youtube-video" */).then(c => wrapFunctional(c.default || c))
export const AttributesList = () => import('../../components/attributesList.vue' /* webpackChunkName: "components/attributes-list" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../components/breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const Crud = () => import('../../components/crud.vue' /* webpackChunkName: "components/crud" */).then(c => wrapFunctional(c.default || c))
export const Draggable = () => import('../../components/draggable.vue' /* webpackChunkName: "components/draggable" */).then(c => wrapFunctional(c.default || c))
export const Money = () => import('../../components/money.vue' /* webpackChunkName: "components/money" */).then(c => wrapFunctional(c.default || c))
export const Status = () => import('../../components/status.vue' /* webpackChunkName: "components/status" */).then(c => wrapFunctional(c.default || c))
export const ActivityLogAction = () => import('../../components/ActivityLog/Action.vue' /* webpackChunkName: "components/activity-log-action" */).then(c => wrapFunctional(c.default || c))
export const ActivityLogItemBody = () => import('../../components/ActivityLog/ItemBody.vue' /* webpackChunkName: "components/activity-log-item-body" */).then(c => wrapFunctional(c.default || c))
export const ActivityLog = () => import('../../components/ActivityLog/index.vue' /* webpackChunkName: "components/activity-log" */).then(c => wrapFunctional(c.default || c))
export const ActivityLogItem = () => import('../../components/ActivityLog/item.vue' /* webpackChunkName: "components/activity-log-item" */).then(c => wrapFunctional(c.default || c))
export const AuthFacebook = () => import('../../components/Auth/Facebook.vue' /* webpackChunkName: "components/auth-facebook" */).then(c => wrapFunctional(c.default || c))
export const AuthFooter = () => import('../../components/Auth/Footer.vue' /* webpackChunkName: "components/auth-footer" */).then(c => wrapFunctional(c.default || c))
export const AuthForgotPasswordEmail = () => import('../../components/Auth/ForgotPasswordEmail.vue' /* webpackChunkName: "components/auth-forgot-password-email" */).then(c => wrapFunctional(c.default || c))
export const AuthForgotPasswordPhone = () => import('../../components/Auth/ForgotPasswordPhone.vue' /* webpackChunkName: "components/auth-forgot-password-phone" */).then(c => wrapFunctional(c.default || c))
export const AuthGoogle = () => import('../../components/Auth/Google.vue' /* webpackChunkName: "components/auth-google" */).then(c => wrapFunctional(c.default || c))
export const AuthLinkedin = () => import('../../components/Auth/Linkedin.vue' /* webpackChunkName: "components/auth-linkedin" */).then(c => wrapFunctional(c.default || c))
export const AuthLogin = () => import('../../components/Auth/Login.vue' /* webpackChunkName: "components/auth-login" */).then(c => wrapFunctional(c.default || c))
export const AuthModal = () => import('../../components/Auth/Modal.vue' /* webpackChunkName: "components/auth-modal" */).then(c => wrapFunctional(c.default || c))
export const AuthRegister = () => import('../../components/Auth/Register.vue' /* webpackChunkName: "components/auth-register" */).then(c => wrapFunctional(c.default || c))
export const AuthSendResetRequest = () => import('../../components/Auth/SendResetRequest.vue' /* webpackChunkName: "components/auth-send-reset-request" */).then(c => wrapFunctional(c.default || c))
export const AuthVerifyCode = () => import('../../components/Auth/VerifyCode.vue' /* webpackChunkName: "components/auth-verify-code" */).then(c => wrapFunctional(c.default || c))
export const AuthVerifyResetPasswordCode = () => import('../../components/Auth/VerifyResetPasswordCode.vue' /* webpackChunkName: "components/auth-verify-reset-password-code" */).then(c => wrapFunctional(c.default || c))
export const AuthVerifyResetPasswordToken = () => import('../../components/Auth/VerifyResetPasswordToken.vue' /* webpackChunkName: "components/auth-verify-reset-password-token" */).then(c => wrapFunctional(c.default || c))
export const AuthVerifyToken = () => import('../../components/Auth/VerifyToken.vue' /* webpackChunkName: "components/auth-verify-token" */).then(c => wrapFunctional(c.default || c))
export const AuthWindowController = () => import('../../components/Auth/WindowController.vue' /* webpackChunkName: "components/auth-window-controller" */).then(c => wrapFunctional(c.default || c))
export const CheckoutContactInfo = () => import('../../components/Checkout/ContactInfo.vue' /* webpackChunkName: "components/checkout-contact-info" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPayment = () => import('../../components/Checkout/Payment.vue' /* webpackChunkName: "components/checkout-payment" */).then(c => wrapFunctional(c.default || c))
export const CheckoutShipping = () => import('../../components/Checkout/Shipping.vue' /* webpackChunkName: "components/checkout-shipping" */).then(c => wrapFunctional(c.default || c))
export const ClientDashboard = () => import('../../components/Client/Dashboard.vue' /* webpackChunkName: "components/client-dashboard" */).then(c => wrapFunctional(c.default || c))
export const DialogClose = () => import('../../components/Dialog/Close.vue' /* webpackChunkName: "components/dialog-close" */).then(c => wrapFunctional(c.default || c))
export const FilterTypeAttribute = () => import('../../components/FilterType/Attribute.vue' /* webpackChunkName: "components/filter-type-attribute" */).then(c => wrapFunctional(c.default || c))
export const FilterTypeBrand = () => import('../../components/FilterType/Brand.vue' /* webpackChunkName: "components/filter-type-brand" */).then(c => wrapFunctional(c.default || c))
export const FilterTypeCategory = () => import('../../components/FilterType/Category.vue' /* webpackChunkName: "components/filter-type-category" */).then(c => wrapFunctional(c.default || c))
export const FilterTypePrice = () => import('../../components/FilterType/Price.vue' /* webpackChunkName: "components/filter-type-price" */).then(c => wrapFunctional(c.default || c))
export const InvoicePaymentStatus = () => import('../../components/Invoice/PaymentStatus.vue' /* webpackChunkName: "components/invoice-payment-status" */).then(c => wrapFunctional(c.default || c))
export const LookupsAttributes = () => import('../../components/Lookups/Attributes.vue' /* webpackChunkName: "components/lookups-attributes" */).then(c => wrapFunctional(c.default || c))
export const LookupsBrands = () => import('../../components/Lookups/Brands.vue' /* webpackChunkName: "components/lookups-brands" */).then(c => wrapFunctional(c.default || c))
export const OrderCrud = () => import('../../components/Order/Crud.vue' /* webpackChunkName: "components/order-crud" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../components/Page/Index.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodsArabbank = () => import('../../components/PaymentMethods/Arabbank.vue' /* webpackChunkName: "components/payment-methods-arabbank" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodsCod = () => import('../../components/PaymentMethods/Cod.vue' /* webpackChunkName: "components/payment-methods-cod" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodsCreditcards = () => import('../../components/PaymentMethods/Creditcards.vue' /* webpackChunkName: "components/payment-methods-creditcards" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodsEfawateercom = () => import('../../components/PaymentMethods/Efawateercom.vue' /* webpackChunkName: "components/payment-methods-efawateercom" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodsHyperpay = () => import('../../components/PaymentMethods/Hyperpay.vue' /* webpackChunkName: "components/payment-methods-hyperpay" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodsHyperpayScript = () => import('../../components/PaymentMethods/HyperpayScript.vue' /* webpackChunkName: "components/payment-methods-hyperpay-script" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodsWallet = () => import('../../components/PaymentMethods/Wallet.vue' /* webpackChunkName: "components/payment-methods-wallet" */).then(c => wrapFunctional(c.default || c))
export const PermissionsAction = () => import('../../components/Permissions/Action.vue' /* webpackChunkName: "components/permissions-action" */).then(c => wrapFunctional(c.default || c))
export const PermissionsField = () => import('../../components/Permissions/Field.vue' /* webpackChunkName: "components/permissions-field" */).then(c => wrapFunctional(c.default || c))
export const PermissionsModel = () => import('../../components/Permissions/Model.vue' /* webpackChunkName: "components/permissions-model" */).then(c => wrapFunctional(c.default || c))
export const PermissionsRoot = () => import('../../components/Permissions/Root.vue' /* webpackChunkName: "components/permissions-root" */).then(c => wrapFunctional(c.default || c))
export const Permissions = () => import('../../components/Permissions/index.vue' /* webpackChunkName: "components/permissions" */).then(c => wrapFunctional(c.default || c))
export const ProductGallery = () => import('../../components/Product/Gallery.vue' /* webpackChunkName: "components/product-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductOfferCountDown = () => import('../../components/Product/OfferCountDown.vue' /* webpackChunkName: "components/product-offer-count-down" */).then(c => wrapFunctional(c.default || c))
export const ProductRating = () => import('../../components/Product/Rating.vue' /* webpackChunkName: "components/product-rating" */).then(c => wrapFunctional(c.default || c))
export const ProfileChangeName = () => import('../../components/Profile/ChangeName.vue' /* webpackChunkName: "components/profile-change-name" */).then(c => wrapFunctional(c.default || c))
export const ProfileChangePassword = () => import('../../components/Profile/ChangePassword.vue' /* webpackChunkName: "components/profile-change-password" */).then(c => wrapFunctional(c.default || c))
export const ProfileMyAddresses = () => import('../../components/Profile/MyAddresses.vue' /* webpackChunkName: "components/profile-my-addresses" */).then(c => wrapFunctional(c.default || c))
export const ProfileMyOrders = () => import('../../components/Profile/MyOrders.vue' /* webpackChunkName: "components/profile-my-orders" */).then(c => wrapFunctional(c.default || c))
export const ProfilePicture = () => import('../../components/Profile/Picture.vue' /* webpackChunkName: "components/profile-picture" */).then(c => wrapFunctional(c.default || c))
export const ShippingsCarriersDropShop = () => import('../../components/ShippingsCarriers/DropShop.vue' /* webpackChunkName: "components/shippings-carriers-drop-shop" */).then(c => wrapFunctional(c.default || c))
export const ToastsSnackbars = () => import('../../components/Toasts/Snackbars.vue' /* webpackChunkName: "components/toasts-snackbars" */).then(c => wrapFunctional(c.default || c))
export const Toasts = () => import('../../components/Toasts/index.vue' /* webpackChunkName: "components/toasts" */).then(c => wrapFunctional(c.default || c))
export const TranslationsAdd = () => import('../../components/Translations/Add.vue' /* webpackChunkName: "components/translations-add" */).then(c => wrapFunctional(c.default || c))
export const TranslationsBulkUpdate = () => import('../../components/Translations/BulkUpdate.vue' /* webpackChunkName: "components/translations-bulk-update" */).then(c => wrapFunctional(c.default || c))
export const TranslationsComparison = () => import('../../components/Translations/Comparison.vue' /* webpackChunkName: "components/translations-comparison" */).then(c => wrapFunctional(c.default || c))
export const TranslationsEditor = () => import('../../components/Translations/Editor.vue' /* webpackChunkName: "components/translations-editor" */).then(c => wrapFunctional(c.default || c))
export const TranslationsFullKey = () => import('../../components/Translations/FullKey.vue' /* webpackChunkName: "components/translations-full-key" */).then(c => wrapFunctional(c.default || c))
export const TranslationsGroup = () => import('../../components/Translations/Group.vue' /* webpackChunkName: "components/translations-group" */).then(c => wrapFunctional(c.default || c))
export const TranslationsImportAndExport = () => import('../../components/Translations/ImportAndExport.vue' /* webpackChunkName: "components/translations-import-and-export" */).then(c => wrapFunctional(c.default || c))
export const TranslationsIsPublished = () => import('../../components/Translations/IsPublished.vue' /* webpackChunkName: "components/translations-is-published" */).then(c => wrapFunctional(c.default || c))
export const TranslationsKey = () => import('../../components/Translations/Key.vue' /* webpackChunkName: "components/translations-key" */).then(c => wrapFunctional(c.default || c))
export const TranslationsPlaceholders = () => import('../../components/Translations/Placeholders.vue' /* webpackChunkName: "components/translations-placeholders" */).then(c => wrapFunctional(c.default || c))
export const TranslationsSearchOldFiles = () => import('../../components/Translations/SearchOldFiles.vue' /* webpackChunkName: "components/translations-search-old-files" */).then(c => wrapFunctional(c.default || c))
export const TranslationsTags = () => import('../../components/Translations/Tags.vue' /* webpackChunkName: "components/translations-tags" */).then(c => wrapFunctional(c.default || c))
export const UserChip = () => import('../../components/User/Chip.vue' /* webpackChunkName: "components/user-chip" */).then(c => wrapFunctional(c.default || c))
export const UserChips = () => import('../../components/User/Chips.vue' /* webpackChunkName: "components/user-chips" */).then(c => wrapFunctional(c.default || c))
export const UserFavoriteInstrumentsEdit = () => import('../../components/User/FavoriteInstrumentsEdit.vue' /* webpackChunkName: "components/user-favorite-instruments-edit" */).then(c => wrapFunctional(c.default || c))
export const UserMenu = () => import('../../components/User/Menu.vue' /* webpackChunkName: "components/user-menu" */).then(c => wrapFunctional(c.default || c))
export const UserStatus = () => import('../../components/User/status.vue' /* webpackChunkName: "components/user-status" */).then(c => wrapFunctional(c.default || c))
export const WebsiteFiltersBrand = () => import('../../components/WebsiteFilters/Brand.vue' /* webpackChunkName: "components/website-filters-brand" */).then(c => wrapFunctional(c.default || c))
export const WebsiteFiltersCheckbox = () => import('../../components/WebsiteFilters/Checkbox.vue' /* webpackChunkName: "components/website-filters-checkbox" */).then(c => wrapFunctional(c.default || c))
export const WebsiteFiltersChips = () => import('../../components/WebsiteFilters/Chips.vue' /* webpackChunkName: "components/website-filters-chips" */).then(c => wrapFunctional(c.default || c))
export const WebsiteFiltersColor = () => import('../../components/WebsiteFilters/Color.vue' /* webpackChunkName: "components/website-filters-color" */).then(c => wrapFunctional(c.default || c))
export const WebsiteFiltersList = () => import('../../components/WebsiteFilters/List.vue' /* webpackChunkName: "components/website-filters-list" */).then(c => wrapFunctional(c.default || c))
export const WebsiteFiltersRange = () => import('../../components/WebsiteFilters/Range.vue' /* webpackChunkName: "components/website-filters-range" */).then(c => wrapFunctional(c.default || c))
export const WidgetComment = () => import('../../components/Widget/Comment.vue' /* webpackChunkName: "components/widget-comment" */).then(c => wrapFunctional(c.default || c))
export const WidgetComments = () => import('../../components/Widget/Comments.vue' /* webpackChunkName: "components/widget-comments" */).then(c => wrapFunctional(c.default || c))
export const WidgetDonutChart = () => import('../../components/Widget/DonutChart.vue' /* webpackChunkName: "components/widget-donut-chart" */).then(c => wrapFunctional(c.default || c))
export const WidgetDonutChartPlatforms = () => import('../../components/Widget/DonutChartPlatforms.vue' /* webpackChunkName: "components/widget-donut-chart-platforms" */).then(c => wrapFunctional(c.default || c))
export const WidgetLatestNews = () => import('../../components/Widget/LatestNews.vue' /* webpackChunkName: "components/widget-latest-news" */).then(c => wrapFunctional(c.default || c))
export const WidgetLineChart = () => import('../../components/Widget/LineChart.vue' /* webpackChunkName: "components/widget-line-chart" */).then(c => wrapFunctional(c.default || c))
export const WidgetLogo = () => import('../../components/Widget/Logo.vue' /* webpackChunkName: "components/widget-logo" */).then(c => wrapFunctional(c.default || c))
export const WidgetSharethis = () => import('../../components/Widget/Sharethis.vue' /* webpackChunkName: "components/widget-sharethis" */).then(c => wrapFunctional(c.default || c))
export const WidgetAmMap = () => import('../../components/Widget/amMap.vue' /* webpackChunkName: "components/widget-am-map" */).then(c => wrapFunctional(c.default || c))
export const BadgeCashback = () => import('../../components/badge/Cashback.vue' /* webpackChunkName: "components/badge-cashback" */).then(c => wrapFunctional(c.default || c))
export const BadgeNew = () => import('../../components/badge/New.vue' /* webpackChunkName: "components/badge-new" */).then(c => wrapFunctional(c.default || c))
export const TranslationsEditorCountable = () => import('../../components/Translations/editor/countable.vue' /* webpackChunkName: "components/translations-editor-countable" */).then(c => wrapFunctional(c.default || c))
export const TranslationsEditorHtml = () => import('../../components/Translations/editor/html.vue' /* webpackChunkName: "components/translations-editor-html" */).then(c => wrapFunctional(c.default || c))
export const TranslationsEditorText = () => import('../../components/Translations/editor/text.vue' /* webpackChunkName: "components/translations-editor-text" */).then(c => wrapFunctional(c.default || c))
export const AttributesInsertCheckboxOption = () => import('../../components/Attributes/Insert/Checkbox/Option.vue' /* webpackChunkName: "components/attributes-insert-checkbox-option" */).then(c => wrapFunctional(c.default || c))
export const AttributesInsertColorOption = () => import('../../components/Attributes/Insert/Color/Option.vue' /* webpackChunkName: "components/attributes-insert-color-option" */).then(c => wrapFunctional(c.default || c))
export const AttributesInsertColor = () => import('../../components/Attributes/Insert/Color/index.vue' /* webpackChunkName: "components/attributes-insert-color" */).then(c => wrapFunctional(c.default || c))
export const AttributesInsertNumberOption = () => import('../../components/Attributes/Insert/Number/Option.vue' /* webpackChunkName: "components/attributes-insert-number-option" */).then(c => wrapFunctional(c.default || c))
export const AttributesInsertNumber = () => import('../../components/Attributes/Insert/Number/index.vue' /* webpackChunkName: "components/attributes-insert-number" */).then(c => wrapFunctional(c.default || c))
export const AttributesInsertTextOption = () => import('../../components/Attributes/Insert/Text/Option.vue' /* webpackChunkName: "components/attributes-insert-text-option" */).then(c => wrapFunctional(c.default || c))
export const AttributesInsertText = () => import('../../components/Attributes/Insert/Text/index.vue' /* webpackChunkName: "components/attributes-insert-text" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
